import React from "react"
import Layout from "@components/layout"
import { graphql } from "gatsby"
import Pagination from "@components/pagination"
import PropTypes from "prop-types"
import Heading from "@components/elements/heading"
import Grid from "@components/elements/grid"
import { BlogCard } from "@components/blog/card"
import { HighGradient } from "@components/common/gradient"
import { useGradient } from "@hooks/use-gradient"
import Div from "@components/elements/div"

const Blogs = ({ data, pageContext }) => {
  const { gradient } = useGradient(true)

  return (
    <Layout
      title={`Blog - Metrics, KPIs and Analytics ${
        pageContext.currentPage === 1 ? "" : `| ${pageContext.currentPage}`
      }`}
      description={
        "The Klipfolio blog is for leaders, teams, agencies, and growing companies. Your one-stop-shop to learn about dashboards, analytics, metrics, and KPIs"
      }
      fullWidth
      marginless
    >
      <Div overflow="hidden" position="relative">
        <Div container>
          <Div
            margin="6rem 0 0"
            padding="2rem 0"
            zIndex={1}
            position="relative"
          >
            <Heading center margin="2rem 0 4rem">
              Klipfolio Blog
            </Heading>
          </Div>

          <HighGradient top="-2rem" src={gradient.cdn} />
          <Grid
            columns="repeat(auto-fill, minmax(280px, 1fr))"
            gap="2rem"
            margin="0 0 60px"
          >
            {data.blogs.edges.map(({ node: blog }) => {
              const date = new Date(blog.date.replace(/-/g, "/"))
              const dateString = date.toLocaleDateString("en-US", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })
              return (
                <BlogCard
                  key={blog.id}
                  image={blog.image}
                  title={blog.title}
                  link={"/" + blog.slug}
                  author={blog.authors[0]?.name}
                  date={dateString}
                />
              )
            })}
          </Grid>
          <Pagination
            style={{ marginBottom: "80px" }}
            currentPage={pageContext.currentPage}
            relativePath={"/blog"}
            totalPages={pageContext.numPages}
          />
        </Div>
      </Div>
    </Layout>
  )
}

Blogs.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Blogs

export const pageQuery = graphql`
  query BlogPageTemplateQuery($limit: Int!, $page: Int!) {
    blogs: allBlog(
      limit: $limit
      skip: $page
      filter: { status: { eq: "published" } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          title
          id
          status
          date
          slug
          image {
            id
            title
            cdn(variation: "blogcard")
            placeholder
          }
          authors {
            name
          }
        }
      }
    }
  }
`
